import { useCallback } from 'react'
import { useGetCurrentTimeInISO8601WithoutTimezone } from '../useGetCurrentTimeInISO8601WithoutTimezone/useGetCurrentTimeInISO8601WithoutTimezone'

export const useGenerateMeetingDeeplink = () => {
    const isoTime = useGetCurrentTimeInISO8601WithoutTimezone()

    const getUrl = useCallback(() => {
        const teamsUrl = process.env.MSTEAMS_CLOUD_APP_URL

        return `${teamsUrl}/l/meeting/new`
    }, [])

    return useCallback(
        (
            subject: string,
            body: string,
            startTime?: string,
            endTime?: string
        ) => {
            // Url search params will automatically encode and replaces any spaces with '+'
            // Teams mobile does not support this encoding so we need to replace '+' with '%20'
            // Url search params will also try encode the % symbol which double encodes the text and it won't be decoded on the receieving end
            // So we must manually build out the URL
            const url = `${getUrl()}?subject=${encodeURIComponent(
                subject
            )}&content=${encodeURIComponent(
                body
            )}&startTime=${encodeURIComponent(
                startTime ?? isoTime
            )}&endTime=${encodeURIComponent(endTime ?? isoTime)}`

            return url
        },
        [getUrl, isoTime]
    )
}
