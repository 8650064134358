import {
    Frame,
    Image,
    PersonalizedQuote,
    ThriveQuote
} from '../graphql/generated/autogenerated'
import { app as teamsApp } from '@microsoft/teams-js'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '../routes/routes'

export type ResetAsset = Frame & (Image | ThriveQuote | PersonalizedQuote)

export const getDurationInMinutes = (durationInSeconds: number) =>
    durationInSeconds > 60 ? Math.floor(durationInSeconds / 60) : 1

export function isImageAsset(asset: ResetAsset): asset is Image {
    if (!asset) return false

    const hasImageType = asset?.__typename === 'Image'
    const hasImageUrl = Boolean((asset as Image)?.urls?.xl)
    const hasThumbnail = Boolean((asset as Image)?.urls?.xs)

    return hasImageType || (hasImageUrl && hasThumbnail)
}

export function isQuoteAsset(asset: ResetAsset): asset is ThriveQuote {
    if (!asset) return false

    return asset?.__typename === 'ThriveQuote'
}

export const isValidHttpUrl = (string) => {
    let url

    try {
        url = new URL(string)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const splitOutQueryParams = (url: string) => {
    const queryParams = url?.split('?')[1]
    const params = queryParams?.split('&')

    return params?.map((param) => {
        const [key, value] = param.split('=')
        return { key, value: decodeURIComponent(value) }
    })
}

export const tryInit = async () => {
    if (teamsApp?.isInitialized()) {
        return Promise.resolve()
    }

    return teamsApp?.initialize()
}

export const getAuthRelayUrl = (route: ROUTES) => {
    const parsedUrl = new URL(`${window.location.origin}${ROUTES.AUTH_RELAY}`)
    parsedUrl.searchParams.set('relay', route.slice(1))

    return {
        fullUrl: parsedUrl.toString(),
        path: parsedUrl.pathname + parsedUrl.search
    }
}
