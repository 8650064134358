import withErrorComponentBoundary from '../../../hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import ShareContentOnTeams from './ShareContentOnTeams'
import ShareContentOnTeamsBranchLink from './ShareContentOnTeamsBranchLink'

export const ShareContentOnTeamsWithFallback = withErrorComponentBoundary(
    'ShareContentOnTeams',
    ShareContentOnTeams
)
export const ShareContentOnTeamsBranchLinkWithFallback =
    withErrorComponentBoundary(
        'ShareContentOnTeamsBranchLink',
        ShareContentOnTeamsBranchLink
    )

export default ShareContentOnTeams
