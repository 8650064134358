import { memo } from 'react'
import withErrorComponentBoundary from '../../../../shared/hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import ScheduleContentButton from './ScheduleContentButton'

export const ScheduleContentButtonWithFallback = withErrorComponentBoundary(
    'ScheduleContentButton',
    ScheduleContentButton
)

export default memo(ScheduleContentButtonWithFallback)
