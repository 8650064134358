import {
    useGenerateTeamsDeepLink,
    useGenerateTeamsUnfurlLink,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import { isValidHttpUrl } from '../../../utils'

export const useTeamsUrl = (
    url: string,
    renderMessageAsDeeplink: boolean,
    renderPathAndUnfurlLink: boolean,
    utm_campaign: string,
    utm_source: string,
    disableTracking?: boolean
) => {
    const enableLinkUnfurling = useIsFeatureEnabled(
        'EnableMsteamsLinkUnfurling',
        false,
        true
    )

    const generateTeamsDeepLink = useGenerateTeamsDeepLink()
    const generateTeamsUnfurlLink = useGenerateTeamsUnfurlLink()

    const isUrlValid = useMemo(() => {
        if (url) {
            return isValidHttpUrl(url)
        }

        return false
    }, [url])

    const renderedUrl = useMemo(() => {
        // Add the utm_source so segment page tracking can be used to determine when a user clicks a shared link
        if (renderMessageAsDeeplink || renderPathAndUnfurlLink) {
            let parsedUrl = url

            if (!disableTracking) {
                const source = `utm_source=${utm_source}&utm_campaign=${utm_campaign}`
                parsedUrl = url?.includes('?')
                    ? `${url}&${source}`
                    : `${url}?${source}`
            }

            return (
                renderPathAndUnfurlLink && enableLinkUnfurling
                    ? generateTeamsUnfurlLink
                    : generateTeamsDeepLink
            )(parsedUrl.toString().substring(1)).toString()
        } else if (isUrlValid) {
            const parsedUrl = new URL(url)
            if (!disableTracking) {
                parsedUrl.searchParams.set('utm_source', utm_source)
                parsedUrl.searchParams.set('utm_campaign', utm_campaign)
            }

            return parsedUrl.toString()
        }
    }, [
        renderMessageAsDeeplink,
        renderPathAndUnfurlLink,
        url,
        utm_source,
        utm_campaign,
        disableTracking,
        isUrlValid,
        enableLinkUnfurling,
        generateTeamsDeepLink,
        generateTeamsUnfurlLink
    ])

    return { renderedUrl, isUrlValid }
}

export default useTeamsUrl
