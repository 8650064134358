export enum ROUTES {
    HOME = '/',
    RESET = '/reset',
    SIDE_PANEL = '/teams/sidepanel',
    DEEP_LINK = '/teams/deeplink',
    CONFIRM_SHARE_RESET = '/teams/confirmsharereset/:id',
    RESET_STAGE_AUTH_SELECTOR = '/teams/resetstageselector',
    RESET_STAGE_AUTH = '/teams/a/resetstage',
    RESET_STAGE_UNAUTH = '/teams/u/resetstage',
    NOTIFICATIONSETTINGS = '/teams/notification/settings',
    RESET_IFRAME_PLAYER = '/teams/resetiframeplayer',
    RESET_PLAYER = '/teams/resetplayer/:resetId',
    JOURNEY_PLAYER = '/teams/journeyplayer/:contentId',
    PERSONALIZED_RESET_PLAYER = '/teams/personalizedresetplayer/:resetId',
    RESET_VOTING_SETUP = '/teams/resetvotingsetup',
    RESET_VOTING_STAGE = '/teams/resetvotingstage',
    PERSONALIZED_MICROSTEPS = '/teams/microsteps/personalized',
    AUTH_RELAY = '/ms-teams/auth',
    EDIT_INTENTION = 'journeys/edit-intention',
    JOURNEY_MICROSTEPS = 'journeys/microsteps'
}
