import { ErrorBoundary, FallbackProps } from '@thriveglobal/thrive-web-errors'
import { ComponentType } from 'react'

export default function withErrorComponentBoundary<
    Props extends JSX.IntrinsicAttributes
>(
    boundaryName: string,
    Component: ComponentType<Props>,
    fallbackComponent?: ComponentType<FallbackProps>
) {
    return (props: Props): JSX.Element => {
        return (
            <ErrorBoundary
                boundaryName={boundaryName}
                boundaryType="Page"
                packageName={process.env.APP_NAME}
                packageOwner={process.env.PROJECT_OWNER}
                packageVersion={process.env.APP_VERSION}
                fallbackComponent={fallbackComponent}
            >
                <Component {...props} />
            </ErrorBoundary>
        )
    }
}
