import { useBranchReferralLink } from '@thriveglobal/thrive-web-core'
import React, { ReactElement, memo, useMemo } from 'react'
import ConditionalSkeleton from '../../../../components/elements/ConditionalSkeleton/ConditionalSkeleton'
import ShareContentOnTeams, {
    ChatSharingProps,
    ShareImageProps
} from './ShareContentOnTeams'

export interface ShareContentOnTeamsBranchLinkProps
    extends JSX.IntrinsicAttributes {
    button?: ReactElement<any, any>
    url: string
    message?: string
    ariaLabel?: string
    onShared?: (shareSuccessful: boolean) => void
    companyName: string
    referrerName: string
    chatSharing?: ChatSharingProps
    image?: ShareImageProps
}

const ShareContentOnTeamsBranchLink: React.FC<
    ShareContentOnTeamsBranchLinkProps
> = ({
    button,
    url,
    message,
    ariaLabel,
    onShared,
    companyName,
    referrerName,
    chatSharing,
    image
}) => {
    const parsedRedirectUrl = useMemo(() => {
        const source = `utm_source=msteams&utm_campaign=people_picker_share`

        return url?.includes('?') ? `${url}&${source}` : `${url}?${source}`
    }, [url])

    const { error, loading, coworkerReferral } = useBranchReferralLink(
        companyName as string,
        referrerName as string,
        parsedRedirectUrl
    )

    return (
        <ConditionalSkeleton showSkeleton={loading} variant="rounded">
            <ShareContentOnTeams
                button={button}
                url={error ? url : coworkerReferral.link}
                message={message}
                ariaLabel={ariaLabel}
                onShared={onShared}
                disableTracking={true}
                chatSharing={chatSharing}
                image={image}
            />
        </ConditionalSkeleton>
    )
}

export default memo(ShareContentOnTeamsBranchLink)
