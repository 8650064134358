import { useMemo } from 'react'

export const useOutlookTime = () =>
    useMemo(() => {
        const date = new Date()

        const month = ('00' + (date.getMonth() + 1)).slice(-2)
        const day = ('00' + date.getDate()).slice(-2)
        const year = date.getFullYear()
        const hour = ('00' + date.getHours()).slice(-2)
        const minute = ('00' + date.getMinutes()).slice(-2)
        const seconds = ('00' + date.getSeconds()).slice(-2)

        return `${month}/${day}/${year} ${hour}:${minute}:${seconds}`
    }, [])
