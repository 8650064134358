import { Skeleton, SkeletonProps } from '@mui/material'
import React, { PropsWithChildren, memo } from 'react'

export interface ConditionalSkeletonProps extends SkeletonProps {
    showSkeleton: boolean
}

const ConditionalSkeleton: React.FC<
    PropsWithChildren<ConditionalSkeletonProps>
> = ({ showSkeleton, children, ...props }) => (
    <>
        {showSkeleton ? (
            <Skeleton {...props} data-testid="skeleton">
                {children}
            </Skeleton>
        ) : (
            <>{children}</>
        )}
    </>
)

export default memo(ConditionalSkeleton)
