import { useCallback } from 'react'
import { useGetCurrentTimeInISO8601WithoutTimezone } from '../useGetCurrentTimeInISO8601WithoutTimezone/useGetCurrentTimeInISO8601WithoutTimezone'

export const useGenerateOutlookWebUrl = () => {
    const isoTime = useGetCurrentTimeInISO8601WithoutTimezone()

    return useCallback(
        (
            subject: string,
            body: string,
            startTime?: string,
            endTime?: string
        ) => {
            const OUTLOOK_URL = process.env.OUTLOOK_CLOUD_URL

            const calendarUrl = new URL(`${OUTLOOK_URL}/owa`)
            calendarUrl.searchParams.append('path', 'calendar/action/compose')
            calendarUrl.searchParams.append('rru', 'addevent')
            calendarUrl.searchParams.append('subject', subject)
            calendarUrl.searchParams.append('body', body)
            calendarUrl.searchParams.append('startdt', startTime ?? isoTime)
            calendarUrl.searchParams.append('enddt', endTime ?? isoTime)

            return calendarUrl.toString()
        },
        [isoTime]
    )
}
