import { Stack, makeStyles } from '@fluentui/react'
import { Spinner, tokens } from '@fluentui/react-components'
import React from 'react'

const useStyles = makeStyles({
    main: {
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: tokens.colorNeutralBackground3
    }
})

const FluentFullScreenLoading: React.FC = () => {
    const styles = useStyles()

    return (
        <Stack className={styles.main} data-testid="fluent-loading">
            <Spinner />
        </Stack>
    )
}

export default FluentFullScreenLoading
