import {
    teamsDarkTheme,
    teamsHighContrastTheme,
    teamsLightTheme
} from '@fluentui/react-components'
import { useMemo } from 'react'
import useTeamsContext from '../useTeamsContext/useTeamsContext'

export const useTeamsTheme = () => {
    const { context } = useTeamsContext()

    return useMemo(() => {
        switch (context?.app?.theme) {
            case 'dark':
                return teamsDarkTheme
            case 'contrast':
                return teamsHighContrastTheme
            case 'default':
            default:
                return teamsLightTheme
        }
    }, [context])
}

export default useTeamsTheme
