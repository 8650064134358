import { app } from '@microsoft/teams-js'
import { ButtonProps } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import {
    LeafExternalLink,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { isValidHttpUrl } from '../../../../utils'
import { useIsMsTeams } from '../../../../shared/hooks/useIsMsTeams/useIsMsTeams'

export interface NavigationButtonProps extends ButtonProps {
    url: string
    onNavigate?: () => void
}

export const NavigationButton = React.forwardRef<
    HTMLButtonElement,
    NavigationButtonProps
>((props, ref) => {
    const { url, onNavigate, ...other } = props

    const navigate = useCrossAppNavigation()
    const { isMsTeams, loading } = useIsMsTeams()
    const THRIVE_URL = process.env.THRIVE_URL
    const MSTEAMS_CLOUD_APP_URL = process.env.MSTEAMS_CLOUD_APP_URL
    const MSTEAMS_APP_URL = process.env.MSTEAMS_APP_URL

    const isUrlValid = useMemo(() => {
        if (url) {
            return isValidHttpUrl(url)
        }

        return false
    }, [url])

    const { onButtonClick, isExternalLink } = useMemo(() => {
        if (isUrlValid) {
            const validUrl = new URL(url)

            if (validUrl.origin === THRIVE_URL) {
                return {
                    onButtonClick: () => {
                        onNavigate?.()
                        navigate(url)
                    },
                    isExternalLink: false
                }
            } else if (
                (validUrl.origin === MSTEAMS_CLOUD_APP_URL ||
                    validUrl.origin === MSTEAMS_APP_URL) &&
                isMsTeams
            ) {
                return {
                    onButtonClick: () => {
                        app.openLink(url).then(() => onNavigate?.())
                    },
                    isExternalLink: false
                }
            } else {
                return {
                    onButtonClick: undefined,
                    isExternalLink: true
                }
            }
        } else {
            return {
                onButtonClick: () => {
                    onNavigate?.()
                    navigate(url)
                },
                isExternalLink: false
            }
        }
    }, [
        url,
        isMsTeams,
        isUrlValid,
        navigate,
        onNavigate,
        THRIVE_URL,
        MSTEAMS_CLOUD_APP_URL,
        MSTEAMS_APP_URL
    ])

    const button = useMemo(() => {
        const baseButton = (
            <LoadingButton
                {...other}
                ref={ref}
                loading={loading}
                fixWidth={true}
            >
                {props.children}
            </LoadingButton>
        )

        return isExternalLink ? (
            <LeafExternalLink
                hideIcon={true}
                underline="none"
                onContinue={() => onNavigate?.()}
                externalLink={url}
                data-testid="external-link"
            >
                {baseButton}
            </LeafExternalLink>
        ) : (
            React.cloneElement(baseButton, {
                onClick: onButtonClick
            })
        )
    }, [
        loading,
        isExternalLink,
        onButtonClick,
        other,
        props.children,
        ref,
        url,
        onNavigate
    ])

    return button
})

export default memo(NavigationButton)
