import { Box, useMediaQuery } from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { LeafChip, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    beta: {
        defaultMessage: 'Beta',
        description: 'beta tag'
    }
})

export type BetaTagProps = {
    fixed?: boolean
}

const BetaTag: React.FC<BetaTagProps> = ({ fixed = true }) => {
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const showLiveshareBetaTag = useIsFeatureEnabled(
        'showLiveshareBetaTag',
        false,
        true
    )

    if (!showLiveshareBetaTag) {
        return null
    }

    return (
        <Box
            sx={{
                ...(fixed && {
                    position: 'fixed',
                    zIndex: 1500
                }),
                ...(fixed &&
                    !isMobile && {
                        left: '16px',
                        top: '16px'
                    })
            }}
        >
            <LeafChip
                color="primary"
                label={formatMessage(messages.beta)}
                variant="filled"
            />
        </Box>
    )
}

export default memo(BetaTag)
