import { useMemo } from 'react'

export const useGetCurrentTimeInISO8601WithoutTimezone = () => {
    return useMemo(() => {
        const millisecondsInMinute = 60000
        const now = Date.now()
        const nowDate = new Date(now)
        const millisecondsTzDifferenceToUtc =
            nowDate.getTimezoneOffset() * millisecondsInMinute
        // We take away the timezone difference so when we convert to ISO string it will be in the user current time
        const tzAdjustedUTCTime = new Date(now - millisecondsTzDifferenceToUtc)

        return tzAdjustedUTCTime.toISOString().split('Z')[0]
    }, [])
}
