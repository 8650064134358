import {
    ErrorScreen,
    ErrorScreenVariant
} from '@thriveglobal/thrive-web-leafkit'
import React, { PropsWithChildren } from 'react'
import FluentFullScreenLoading from '../Fluent/FluentFullScreenLoading/FluentFullScreenLoading'
import BetaTag from '../../../components/features/LiveShare/BetaTag/BetaTag'

export type PageStateProps = PropsWithChildren<{
    loading: boolean
    error: boolean
    showContent?: boolean
    showBetaTag?: boolean
    loadingComponent?: React.ReactNode
}>

const PageState: React.FC<PageStateProps> = ({
    loading,
    error,
    showContent = true,
    showBetaTag = false,
    loadingComponent = <FluentFullScreenLoading />,
    children
}) => (
    <>
        {showBetaTag && <BetaTag />}
        {loading && !error && loadingComponent}
        {error === true && <ErrorScreen variant={ErrorScreenVariant.General} />}
        {!loading && !error && showContent && children}
    </>
)

export default PageState
