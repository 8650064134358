import { memo } from 'react'
import withErrorComponentBoundary from '../../../hooks/withErrorComponentBoundary/withErrorComponentBoundary'
import NavigationButton from './NavigationButton'

export const NavigationButtonWithFallback = withErrorComponentBoundary(
    'NavigationButton',
    NavigationButton
)

export default memo(NavigationButtonWithFallback)
