import { FluentProvider } from '@fluentui/react-components'
import { store, ThriveApplication } from '@thriveglobal/thrive-web-core'
import React from 'react'
import { Provider } from 'react-redux'
import Router from './routes'
import useTeamsTheme from './shared/hooks/useTeamsTheme/useTeamsTheme'

const loadi18n = (lang: string) =>
    fetch(`${process.env.CF_SOURCE_URL}/lang/${lang}.json`)
        .then((res) => res.json())
        .catch(console.warn)

export default function Root(props) {
    const msTeamsTheme = useTeamsTheme()

    return (
        <ThriveApplication
            messagesLoaders={[loadi18n]}
            apolloClientOptions={{
                cacheOptions: {},
                name: 'thrive-web-ms-teams-app'
            }}
            disableMaxWidth
        >
            <FluentProvider
                style={{
                    minHeight: '0px',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0',
                    overflow: 'hidden'
                }}
                theme={msTeamsTheme}
            >
                <Provider store={store}>
                    <Router />
                </Provider>
            </FluentProvider>
        </ThriveApplication>
    )
}

// Global __THRIVE__ object
declare global {
    interface Window {
        __THRIVE__: any
    }
}
;(window.__THRIVE__ = window.__THRIVE__ || []).push({
    app: process.env.APP_NAME,
    version: process.env.APP_VERSION,
    created: process.env.CREATED_AT
})
