import { app as teamsApp } from '@microsoft/teams-js'
import { useEffect, useRef, useState } from 'react'
import useIsMsTeams from '../useIsMsTeams/useIsMsTeams'

export const useTeamsContext = () => {
    const firedGetContext = useRef(false)
    const { isMsTeams, loading, error: msTeamError } = useIsMsTeams()
    const [context, setContext] = useState<teamsApp.Context>()
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        async function getContext() {
            await teamsApp
                .getContext()
                .then(setContext)
                .catch(() => {
                    setContext(undefined)
                    setError(true)
                })
        }

        if (isMsTeams && !context?.user?.id && !firedGetContext.current) {
            firedGetContext.current = true
            getContext()
        }
    }, [isMsTeams, context, firedGetContext])

    return { isMsTeams, context, error: error || msTeamError, loading }
}

export default useTeamsContext
