import {
    store,
    useAppSelector,
    useIsFeatureEnabled,
    useSegment,
    userSetThriveTosAcceptedServerTime
} from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useState } from 'react'
import {
    useSetUserTncAcceptanceTimestampMutation,
    useSubscribeUserMutation
} from '../../../graphql/generated/autogenerated'
import { SegmentEvents } from '../../enums/segmentEvents'
import useIsMsTeams from '../useIsMsTeams/useIsMsTeams'

export const useMsTeamsTos = () => {
    const analytics = useSegment()
    const { thriveTosAcceptedServerTime } = useAppSelector(
        (state) => state.user
    )
    const [tosAccepted, setTosAccepted] = useState(false)
    const [subscribedToDci, setSubscribedToDci] = useState(false)
    const { isMsTeams } = useIsMsTeams()

    const welcomeMessageEnabled = useIsFeatureEnabled(
        'ms_teams_automatic_dci_tos',
        false,
        true
    )

    const [setUserTncAcceptanceTimestampMutation] =
        useSetUserTncAcceptanceTimestampMutation({
            onCompleted: (data) => {
                if (!data) return
                const { serverTime } = data.identity.user.acceptToS
                store.dispatch(userSetThriveTosAcceptedServerTime(serverTime))
            }
        })

    const [subscribeUserMutation] = useSubscribeUserMutation()

    const onSubscribeToDci = useCallback(
        () =>
            subscribeUserMutation().then(() =>
                analytics?.track(SegmentEvents.survey_subscribed, {
                    feature_type: 'journeys',
                    activity_type: 'dci_subscribed',
                    is_onboarding: true,
                    onboarding_attempt_number: 1,
                    dci_frequency: 'DCI_FREQ_DAILY'
                })
            ),
        [subscribeUserMutation, analytics]
    )

    const trySubscribeToDci = useCallback(
        () =>
            new Promise((resolve) => {
                if (isMsTeams && welcomeMessageEnabled) {
                    onSubscribeToDci().then(() => resolve(false))
                }

                resolve(false)
            }),
        [isMsTeams, welcomeMessageEnabled, onSubscribeToDci]
    )

    useEffect(() => {
        if (isMsTeams && welcomeMessageEnabled) {
            const params = new URLSearchParams(window.location.search)
            setTosAccepted(Boolean(params.get('accepted_tos')) ?? false)
            setSubscribedToDci(
                Boolean(params.get('subscribed_to_dci')) ?? false
            )
        }
    }, [isMsTeams, welcomeMessageEnabled])

    useEffect(() => {
        if (tosAccepted && !thriveTosAcceptedServerTime) {
            setUserTncAcceptanceTimestampMutation().then(() => {
                if (subscribedToDci) {
                    onSubscribeToDci()
                }
            })
        } else if (subscribedToDci) {
            onSubscribeToDci()
        }
    }, [
        tosAccepted,
        thriveTosAcceptedServerTime,
        subscribedToDci,
        setUserTncAcceptanceTimestampMutation,
        onSubscribeToDci
    ])

    return { tosAccepted, subscribedToDci, trySubscribeToDci }
}

export default useMsTeamsTos
