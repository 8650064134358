import { useMemo } from 'react'
import useIsMsTeams from '../useIsMsTeams/useIsMsTeams'

export interface ModuleType {
    isSupported: () => boolean
}

export const useTeamsEnabledModule = (module?: ModuleType) => {
    const { isMsTeams, loading, error } = useIsMsTeams()

    const enabled = useMemo(() => {
        try {
            return (
                isMsTeams &&
                !loading &&
                !error &&
                !!module &&
                module?.isSupported()
            )
        } catch (exception) {
            return false
        }
    }, [isMsTeams, loading, error, module])

    return { enabled, isMsTeams, loading, error }
}

export default useTeamsEnabledModule
